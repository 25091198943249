.loginRequestContainer {
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  .loginText {
    padding: 20px;

    .loginLink {
      margin: 0;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .button {
    margin-top: 10px;
  }
}
