// Neutrals
$white: #fff;
$black: #000;
$grey: #787878;
$midnight-blue: #1f1440;
$karl-grey: #efeef1;
$textGrey: #c4c4c4;

// App Colors
$resource-red: #e6294f;
$tehama-purple: #654bfa;
$kent-gray: #bcc2d1;
$success-green: #009769;

// Brand Colors
$electric-red: #ff2e57;
$royal-purple: #7348ff;
$crystal-blue: #03eaff;
$florida-orange: #ff7227;
$marine-green: #00de9b;
$banana-yellow: #ffd000;
$pacific-blue: #006aff;
$brick-red: #f33e22;
$tooltip-fill: #472be1;
$dark-text: #222222;
$muted-purple: #78728c;

// Brand Colors
$resource-midnight-blue: #1f1440;
$resource-royal-purple: #7348ff;
$resource-crystal-blue: #38e4ff;
$resource-red: #ff2e57;
$resource-grey: #efeef1;
$resource-white: #ffffff;
$resource-background-white: #fafafa;
$resource-orange: #ff7227;

$resource-primary-font: Circular;
$resource-secondary-font: "Lato", sans-serif;

// Other Brand Colors
$linkedInBlue: #4875b4;

// App Color Shades + Tints
$resource-red-80: #ff8fa5;
$resource-red-100: #ff708d;
$resource-red-200: #ff5274;
$resource-red-300: #ff335c;
$resource-red-400: $resource-red;
$resource-red-500: #c72041;
$resource-red-600: #a81835;
$resource-red-700: #8a162d;
$resource-red-800: #6b1325;
$resource-red-900: #4d0f1c;

$tehama-purple-20: #f4f2ff;
$tehama-purple-50: #ded9ff;
$tehama-purple-90: #beb3ff;
$tehama-purple-100: #a899ff;
$tehama-purple-200: #9380ff;
$tehama-purple-300: #7d66ff;
$tehama-purple-400: $tehama-purple;
$tehama-purple-500: #482de0;
$tehama-purple-600: #341cba;
$tehama-purple-700: #251294;
$tehama-purple-800: #16076e;
$tehama-purple-900: #0b0047;

$kent-gray-10: #fafbfc;
$kent-gray-20: #f5f6f7;
$kent-gray-30: #edeff2;
$kent-gray-40: #e1e4eb;
$kent-gray-50: #d8dce6;
$kent-gray-100: $kent-gray;
$kent-gray-200: #a8afbf;
$kent-gray-300: #939aab;
$kent-gray-400: #7b8396;
$kent-gray-500: #687082;
$kent-gray-600: #565d6e;
$kent-gray-700: #444a59;
$kent-gray-800: #333845;
$kent-gray-900: #232730;

$karl-gray-1: #fdfdfe;
$karl-gray-2: #f9f8f9;
$karl-gray-3: #f5f5f7;
$karl-gray-4: #f2f1f4;
$karl-gray-5: #f0eff2;
$karl-gray-6: #e3e2e5;
$karl-gray-7: #d7d6d9;
$karl-gray-8: #bfbec1;
$karl-gray-9: #8f8f91;
$karl-gray-10: #605f60;

$florida-orange-20: #fff3e6;
$florida-orange-300: #ff8e47;
$florida-orange-400: $florida-orange;
$florida-orange-500: #ed5813;
$florida-orange-600: #e64102;
$florida-orange-700: #cc3102;

$brick-red-20: #ffdcd6;
$brick-red-300: #f33e22;
$brick-red-400: #d4341c;
$brick-red-500: #b52914;
$brick-red-600: #8f1c0a;

$marine-green-20: #e6fff7;
$marine-green-300: #00de9b;
$marine-green-400: #00cb90;
$marine-green-500: #00b380;
$marine-green-600: #008354;
$marine-green-700: #008354;

$pacific-blue-20: #f2f8ff;
$pacific-blue-200: #3d9bff;
$pacific-blue-300: #1f84ff;
$pacific-blue-400: $pacific-blue;

$crystal-blue-20: #e6fffc;
$crystal-blue-50: #c7fffb;
$crystal-blue-100: #a3fffd;
$crystal-blue-200: #80fbff;
$crystal-blue-250: #5cf1ff;
$crystal-blue-300: #38e4ff;
$crystal-blue-400: #27c3e6;
$crystal-blue-500: #0ea0cc;
$crystal-blue-600: #0080b3;
$crystal-blue-700: #006699;
$crystal-blue-800: #004f80;

$banana-yellow-20: #fff8cc;
$banana-yellow-50: #fff199;
$banana-yellow-100: #ffe866;
$banana-yellow-200: #ffdd33;
$banana-yellow-250: #ffd000;
$banana-yellow-300: #f7be00;
$banana-yellow-400: #f2ae00;
$banana-yellow-500: #ed9e00;
$banana-yellow-600: #e68e00;
$banana-yellow-700: #e07f00;

// $electric-red Tints & Shades
$electric-red-lighten-1: tint($electric-red, 5%);
$electric-red-lighten-2: tint($electric-red, 20%);
$electric-red-lighten-3: tint($electric-red, 24%);
$electric-red-lighten-4: tint($electric-red, 60%);
$electric-red-lighten-5: tint($electric-red, 90%);
$electric-red-darken-1: shade($electric-red, 5%);
$electric-red-darken-2: shade($electric-red, 10%);
$electric-red-darken-3: shade($electric-red, 20%);
$electric-red-darken-4: shade($electric-red, 40%);
$electric-red-darken-5: shade($electric-red, 60%);

$resource-midnight-blue-300: #79728c;
