@import "../modules/variables";

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-book.otf) format("opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-book-italic.otf) format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-black.otf) format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-black-italic.otf) format("opentype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-bold.otf) format("opentype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-bold-italic.otf) format("opentype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-medium.otf) format("opentype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Circular;
  src: url(~/assets/fonts/circular/circular-medium-italic.otf)
    format("opentype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
