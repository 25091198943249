@import "./fonts/circular.scss";
@import "./fonts/font-classes.scss";
@import "./modules/colors";

html,
body {
  font-family: $resource-primary-font !important;
  background: #ffffff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  button {
    &:focus {
      // This is bad for accessibility so we need to come up with a solution for those who use tab to navigate buttons
      outline: 0 !important;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.divider {
  width: 95%;
  border: 1px solid #eeeeee;
  margin-top: 12px;
  margin-bottom: 0px;
}

textarea,
select,
input {
  padding: 15px;
  background: $resource-white;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 4px;
}

// Base Resource Typography

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Circular;
  font-style: normal;
  color: $resource-midnight-blue;
  margin: 0px 20px 0px 5px;
}

h1,
h2,
h3 {
  font-weight: bold;
}

h1 {
  font-size: 64px;
  line-height: 68px;
  letter-spacing: -1px;
}

h2 {
  font-size: 52px;
  line-height: 58px;
  letter-spacing: -0.8px;
}

h3 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.4px;
}

h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.2px;
}

h5 {
  font-weight: 900;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.2px;
}

h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

p {
  font-family: Circular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $resource-midnight-blue;
}
