@import "~/styles/variables";

.loginBtn {
  padding: 0px;
  display: flex;
  align-items: center;
  color: white;
  width: 260px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
}

.loginBtn:active {
  cursor: pointer;
}

.loginBtn:focus {
  outline: 0;
}

.googleBtn {
  @extend .loginBtn;
  background-color: $electric-red;
  border: thin solid $electric-red;
  margin: 15px 0;
}

.googleBtn:active {
  border-color: $electric-red-darken-2;
  background-color: $electric-red-darken-2;
}

.microsoftBtn {
  @extend .loginBtn;
  background-color: $pacific-blue-300;
  border: thin solid $pacific-blue-300;
}

.microsoftBtn:active {
  border-color: $pacific-blue-darken-2;
  background-color: $pacific-blue-darken-2;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 48px;
  flex-grow: 0;
}

.iconGoogle {
  @extend .icon;
}

.iconMicrosoft {
  @extend .icon;
  background-size: 20px;
  background-position: center;
  background-color: #fff;
}

div.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  span.buttonText {
    font-family: "Circular", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 700;
  }
}
