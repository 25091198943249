@import "../../styles/modules/colors.scss";

$height: 16px;

.container {
  color: $resource-midnight-blue-300;
  font-size: 13px;
  text-align: center;
}
.powered {
  margin-top: 10px;
}
.attribution {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    height: $height;
    width: $height;
    vertical-align: middle;
  }

  .text {
    vertical-align: middle;
  }

  .emoji {
    vertical-align: middle;
    margin-top: -4px;
    display: inline-block;
    height: $height;
    width: 22px;
    font-size: 16px;
  }
}

.share {
  margin-top: $height;
  text-decoration: underline;
}
