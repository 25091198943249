@import "~/styles/config.scss";

.navbar {
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  .topNavSection {
    .navItem {
      &:first-child {
        margin-bottom: 42px;
      }
    }
  }
  .topNavSection,
  .bottomNavSection {
    width: 100%;
  }
  .bottomNavSection {
    margin-bottom: 25px;
  }
  .noText {
    &:after {
      top: 0;
    }
  }
  .navItem {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: $kent-gray-200;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 15px;
      border-radius: 50%;
      right: -4px;
      width: 8px;
      height: 8px;
      transition: background-color 0.3s ease-in;
      background-color: transparent;
    }
    .text {
      line-height: 12px;
    }

    &.active {
      & .text {
        color: $resource-royal-purple;
      }
      & .icon {
        color: $resource-royal-purple;
      }
      & .templatesIcon {
        rect {
          stroke: $resource-royal-purple;
        }
        --fa-primary-color: #{$resource-royal-purple};
      }
      &.navItem:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 50%;
        right: -4px;
        width: 8px;
        height: 8px;
        background-color: $resource-royal-purple;
        color: $resource-royal-purple;
      }
      &.navItem.noText:after {
        top: 7px;
      }
    }
    &:hover {
      text-decoration: none;
      cursor: pointer;
      &:not(.active) .text {
        color: tint($resource-midnight-blue, 40%);
      }
      &:not(.active) .icon {
        color: tint($resource-midnight-blue, 40%);
      }
      &:not(.active) .templatesIcon {
        --fa-primary-color: #{tint($resource-midnight-blue, 40%)};
        rect {
          stroke: tint($resource-midnight-blue, 40%);
        }
      }
    }
    padding: 0 8px;
    .logo {
      height: 30px;
      width: 30px;
      margin: 25px;
      margin-bottom: 0;
    }
    .text {
      transition: color 0.2s ease-in;
    }
    .icon {
      transition: color 0.2s ease-in;
      height: 21px;
      width: 25px;
      margin-bottom: 6px;
    }
    .templatesIcon {
      width: 21px;
      margin-bottom: 6px;
      rect {
        transition: stroke 0.2s ease-in;
        stroke: $kent-gray-200;
      }
    }
    .selectedCircle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}
