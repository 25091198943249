@import "~/styles/config.scss";

.gridCont {
  display: grid;
  grid-template-columns: 104px auto;
  grid-template-rows: auto;
  grid-template-areas: "sidebar main";
  .sidebar {
    position: fixed;
    grid-area: sidebar;
    height: 100vh;
    padding: 12px;
    width: 100px;
    z-index: 1000;
  }
  .mainCont {
    grid-area: main;
    min-height: 100vh;
    $headerHeight: 75px;
    scroll-margin-bottom: 50px;
  }
  .contentCont {
    @include containerStyles;
    margin-top: 60px;
  }
  .footer {
    height: 50px;
    background: transparent;
  }
}
.customerSwitcher {
  position: absolute;
  width: 200px;
  right: 5%;
  top: 1rem;
}
